import React from 'react';
import {Translate} from '../../components/translations';
import Section from '../../components/section';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import Button from '../../components/button';
import Tabs from '../../components/mediaKitTabs';

const Roadmap = () => (
  <Layout>
    <PageHero title="Hexomatic Roadmap" subtitle="" titleSmall className="special-section" />
    <Section about>
      <Tabs />
      <div className="col-md-12">
        <p>
          Most day-to-day tasks cannot be automated without developers or complex enterprise-level platforms. Tomorrow,
          without access to the cloud you’ll be stuck in the stone age.
        </p>
        <p>
          Our mission is to democratize access to the cloud and automate sales, marketing, or research tasks on
          autopilot.
        </p>
        <p>Our internal roadmap is focused on making web scraping and work automation accessible to all.</p>
        <p>
          Follow our progress via our <a href="https://hexomatic.com/academy/changelog/">changelog</a>.
        </p>
      </div>
    </Section>
  </Layout>
);

export default Roadmap;
